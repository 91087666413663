<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Arrange the following ions based on their size. Click and drag the elements to sort.
      </p>
      <drag-to-rank-input
        v-model="inputs.input1"
        :items="itemList"
        class="mb-0"
        style="width: 500px"
        prepend-text="Largest"
        append-text="Smallest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput';

export default {
  name: 'Question253',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          return {
            items: ['$\\text{K}^+$', '$\\text{Br}^-$', '$\\text{Ca}^{2+}$', '$\\text{Se}^{2-}$'],
          };
        case 2:
          return {
            items: ['$\\text{Na}^+$', '$\\text{Cl}^-$', '$\\text{Mg}^{2+}$', '$\\text{S}^{2-}$'],
          };
        case 3:
          return {
            items: ['$\\text{Li}^+$', '$\\text{F}^-$', '$\\text{Be}^{2+}$', '$\\text{O}^{2-}$'],
          };
        case 4:
          return {
            items: ['$\\text{Rb}^+$', '$\\text{I}^-$', '$\\text{Sr}^{2+}$', '$\\text{Te}^{2-}$'],
          };
        default:
          return {
            items: [],
          };
      }
    },
    itemList() {
      return this.versionData.items;
    },
  },
};
</script>
